$(document).ready(function() {

	var show = $('.js-show'),
		hide = $('.js-hide');

	$.each(show, function() {
		$(this).removeClass('js-show');
	})

	$.each(hide, function() {
		$(this).addClass('hidden');
	})
})