
$(document).ready( function() {
	var labels = $('label');

	$.each(labels, function() {
		var inputs = $(this).siblings('span.block').children('input'),
			selects = $(this).siblings('span.block').children('select'),
			that = this;

		inputs.on('focus', function(that) {
			$(this).parent('span.block').siblings('label').addClass('focus');
		})

		selects.on('focus', function(that) {
			$(this).parent('span.block').siblings('label').addClass('focus');
		})

		inputs.on('blur', function(that) {
			$(this).parent('span.block').siblings('label').removeClass('focus');
		})

		selects.on('blur', function(that) {
			$(this).parent('span.block').siblings('label').removeClass('focus');
		})
	})
})