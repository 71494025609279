
function setDatepicker() {
	$('input.datepicker').datepicker({
		format: "dd.mm.yyyy",
		language: "de",
		forceParse: false,
		autoclose: true,
		todayHighlight: true,
		toggleActive: true
	})

	$('.datepicker').datepicker();
}

// Set Typeahead.
function setTypeahead(that) {
	if($(that).hasClass('classic')) {
		var source = suggestions[$(that).attr('data-typeahead')];
		$(that).typeahead({
			highlight: true,
		},
		{
			source: substringMatcher(source),
		});
	} else if ($(that).hasClass('extra')) {
		var source = suggestions[$(that).attr('data-typeahead')];
		$(that).typeahead({
			highlight: true,
		},
		{
			source : arrayMatcher(source),
			display: 'value',
			templates: {
				suggestion: function(data) {
					return '<div><strong>' + data.value + '</strong> - ' + data.city + '</div>';
				}
			}
		});
	}
}

var substringMatcher = function(strs) {
  return function findMatches(q, cb) {
	var matches, substringRegex;

	// an array that will be populated with substring matches
	matches = [];

	// regex used to determine if a string contains the substring `q`
	substrRegex = new RegExp(q, 'i');

	// iterate through the pool of strings and for any string that
	// contains the substring `q`, add it to the `matches` array
	$.each(strs, function(i, str) {
	  if (substrRegex.test(str)) {
		matches.push(str);
	  }
	});

	cb(matches);
  }
}

function arrayMatcher(strs) {
	return function findMatches(q, cb) {
		var matches = [],
			substrRegex = new RegExp('^' + q, 'i'),
			substringRegex;

		$.each(strs, function(i, str) {
			if(substrRegex.test(str.value)) {
				matches.push(str);
			}
		})

		cb(matches);
	}
}

//gets the sibling table and dublicates the last row with empty input.
function dublicateRow() {
	if(!this.id.length) return;

	var targetTable = $('table.' + this.id),
		rows = targetTable.find('tr'),
		lastRow = rows[rows.length - 1],
		lastRowIndex = parseInt( $(lastRow).attr('data-index')),
		newRowIndex = lastRowIndex + 1,
		newRow = lastRow.cloneNode(true),
		newRowInputs = $(newRow).find('input:not(.tt-hint)');

	$.each(newRowInputs, function() {
		//Assign the new index.
		$(this).attr('name',
			$(this).attr('name')
				.replace('[' + lastRowIndex + ']', '[' + newRowIndex + ']'))

		if($(this).hasClass('tt-input')) {
			var newInput = $('<input>', {
					'class': this.className,
					'name': this.name,
					'type': 'text',
					'data-typeahead': $(this).attr('data-typeahead'),
				});

			$(this).closest('td').append(newInput);
			$(this).parent().remove();

			setTypeahead(newInput);
		}

		if($(this).attr('type') === 'checkbox' ) {
			$(this).attr('checked', false);
		} else if($(this).attr('name').indexOf('[id]') >= 0) {
			$(this).attr('val', newRowIndex);
			$(this).val(newRowIndex)
		} else {
			$(this).attr('val', '');
			$(this).val('');
		}
	})

	$(newRow).attr('data-index', newRowIndex);

	targetTable.append(newRow);
}


jQuery(document).ready( function() {

	setDatepicker();

	// Set Typeahead.
	$.each( $('.typeahead'), function() {
		console.log( this );
		setTypeahead(this);
	});

	// Set row dublicator.
	$.each( $('.rowDublicator'), function() {
		$(this).on( 'click', dublicateRow);
	})


	//Set the sticky elements.
	var bodyCoordinates = document.body.getBoundingClientRect(),
		parentCoordinates,
		coordinates;

	//Keep the sticky elemnts at the top of the screen
	$(window).resize(checkResize);
	$(window).scroll(checkPosition);
	checkPosition();

	function checkResize() {
		bodyCoordinates = document.body.getBoundingClientRect();

		checkPosition();
	}

	function checkPosition() {
		$.each( $('.sticky'), function() {
			$(this).removeAttr('style');
			$(this).removeAttr('css');

			coordinates = this.getBoundingClientRect();
			parentCoordinates = this.parentElement.getBoundingClientRect();

			setPosition(this, coordinates, parentCoordinates);
		})
	}

	function setPosition(that, coordinates, parentCoordinates) {
		if (!$(that).hasClass('fixed') && parentCoordinates.top < 0 && coordinates.height < bodyCoordinates.height) {
			$(that).addClass('fixed');
		} else if ($(that).hasClass('fixed') && parentCoordinates.top >= 0 || coordinates.height > bodyCoordinates.height) {
			$(that).removeClass('fixed');
		}
	}


	// Show selected files.
	function displayFileName(that, target) {
		var fileName = '',
			//The spuare brackets need the be escaped for jQuery.
			targetName = that.name.replace(/\[/g, "\\\[").replace(/]/g, "\\\]");
			target = $('#' + targetName + '_js_target'),
			targetVal = target.innerHTML;

		fileName = that.value.split('\\').pop();

		if(fileName)
			$(target).html(fileName);
		else
			$(target).targetVal;
	}

	var inputs = $('.file-control');

	$.each(inputs, function() {

		displayFileName(this);

		$(this).on('change', function(e) {
			displayFileName(e.target);
		});
	});
})
